import { UIKitDialogContainer } from 'app/shared/ui-kit/dialog/dialog-container';
import { DialogConfig } from '@angular/cdk/dialog';


export interface UIKitDialogConfig<D = unknown> extends DialogConfig {
  hasBackdrop?: boolean;
  disableClose?: boolean;
  width?: string;
  height?: string;
  container?: any;
  panelClass?: string | string[];
  data?: D;
}
export class UIKitDefaultDialogConfig extends DialogConfig implements UIKitDialogConfig {
  width = '90vw';
  maxWidth = '1000px';
  maxHeight = '85vh';
  container = UIKitDialogContainer;
}

