import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { UIKitDialogContainer } from 'app/shared/ui-kit/dialog/dialog-container';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { DialogType } from './dialog.type';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  DialogType = DialogType;
  constructor(private dialog: Dialog) { }

  openDialog<R extends string | boolean>(
    title: string | { key: string, value: string } = '',
    body: string = '',
    widthPx: number = 412,
    type: DialogType = DialogType.Warning,
    trueOption: string = 'INSIGHT.GLOBAL.YES',
    falseOption: string = 'INSIGHT.GLOBAL.NO',
    translate = true,
    label = '',
    value?: unknown,
    placeholder?: unknown,
    translationParameter: string = '',
    description: string = ''
  ): Observable<R> {
    const dialogConfigData = {
      title: title,
      body: body,
      label: label,
      value: value,
      widthPx: widthPx || 440,
      placeholder: placeholder,
      trueOption: trueOption,
      falseOption: falseOption,
      type: type,
      translate: translate,
      translationParameter: translationParameter,
      description: description,
    };
    return this.dialog
      .open<R, typeof dialogConfigData, ConfirmationDialogComponent>(
        ConfirmationDialogComponent,
        {
          container: UIKitDialogContainer,
          width: `${widthPx}px`,
          maxHeight: '100%',
          maxWidth: '770px',
          height: 'auto',
          autoFocus: type !== DialogType.Delete,
          disableClose: true,
          data: dialogConfigData,
          restoreFocus: false,
        }
      )
      .closed;
  }

  openRemoveDialog(
    title: string = 'INSIGHT.ADMIN.GENERAL.CONFIRM_DELETE_TITLE',
    body: string = 'INSIGHT.ADMIN.GENERAL.CONFIRM_DELETE_DESC',
    widthPx?: number,
    trueOption: string = 'INSIGHT.GLOBAL.REMOVE',
    falseOption: string = 'INSIGHT.GLOBAL.CANCEL'
  ): Observable<boolean> {
    return this.openDialog(
      title,
      body,
      widthPx,
      DialogType.Delete,
      trueOption,
      falseOption
    );
  }

  openNavigationDialog(): Observable<boolean> {
    return this.openDialog(
      'INSIGHT.ADMIN.GENERAL.CONFIRM_TITLE',
      'INSIGHT.ADMIN.GENERAL.CONFIRM_DESC',
      undefined
    );
  }

  openInfoDialog(
    title: string | { key: string, value: string } = '',
    body = '',
    widthPx?: number,
    trueOption: string = 'INSIGHT.GLOBAL.OK',
    falseOption: string = null,
    translationParameter: string = ''
  ): Observable<boolean> {
    return this.openDialog(
      title,
      body,
      widthPx,
      DialogType.Info,
      trueOption,
      falseOption,
      true,
      undefined,
      undefined,
      undefined,
      translationParameter
    );
  }

  openInputDialog(
    title: string | { key: string, value: string } = '',
    body = '',
    widthPx = 320,
    trueOption: string = 'INSIGHT.GLOBAL.SAVE',
    falseOption: string = 'INSIGHT.GLOBAL.CANCEL',
    label = '',
    value = '',
    placeholder = '',
    translationParameter = '',
    description = ''
  ): Observable<string | false> {
    return this.openDialog(
      title,
      body,
      widthPx,
      DialogType.Input,
      trueOption,
      falseOption,
      true,
      label,
      value,
      placeholder,
      translationParameter,
      description
    );
  }
}
